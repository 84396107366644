import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Stations from "./stations";
import Tooltip from "./tooltip";
import VideoEvents from "./video-events";
import { scaleLinear } from "d3";
import { breakpoints } from "../../models";

interface MapboxMapStandardProps {
  date: Date;
  category: string | undefined;
  overlayOpen: boolean;
  selectedEvents: any;
  setVideoOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setVideoData: React.Dispatch<React.SetStateAction<string>>;
  overlayTriggeredByButton: boolean;
  setOverlayTriggeredByButton: React.Dispatch<React.SetStateAction<boolean>>;
  zoom: number;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  zoomMultiplicator: number;
  setZoomMultiplicator: React.Dispatch<React.SetStateAction<number>>;
}

const MapContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
`;

mapboxgl.accessToken =
  "pk.eyJ1IjoiaG9uaWdtaXRvZSIsImEiOiJja3g1emlpa2kwNTE4Mm5vMDd4b3ZlY245In0.XhnoyYipXmdgL2mAeo9V4Q";

const MapboxMapStandard = ({
  date,
  category,
  overlayOpen,
  selectedEvents,
  setVideoOpen,
  setVideoData,
  overlayTriggeredByButton,
  setOverlayTriggeredByButton,
  zoom,
  setZoom,
  zoomMultiplicator,
  setZoomMultiplicator,
}: MapboxMapStandardProps) => {
  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);
  const [lng, setLng] = useState(10.879);
  const [lat, setLat] = useState(51);

  const [dwdData, setDwdData] = useState<Object | undefined>(undefined);
  const [selectedStation, setSelectedStation] = useState<string>("00001");
  const [tooltipPosition, setTooltipPosition] = useState<[number, number]>([
    0, 0,
  ]);
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style:
        "mapbox://styles/honigmitoe/clbb1cxft000214ow2aaqt7a5?optimize=true",
      center: [lng, lat],
      zoom: zoom,
      maxZoom: 13,
      minZoom: 4.3,
      maxBounds: [
        [-15, 40], // [west, south]
        [38, 58], // [east, north]
      ],
    });
    const windowWidth = mapContainer.current.offsetWidth;
    setWidth(windowWidth);
    map.current.jumpTo({
      padding: {
        right: windowWidth >= breakpoints.desktopS ? 440 : 0,
        top: windowWidth >= breakpoints.desktopS ? 0 : 100,
      },
    });
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

  useEffect(() => {
    if (overlayTriggeredByButton) {
      map.current.jumpTo({
        padding: {
          right: overlayOpen && width >= breakpoints.desktopS ? 440 : 0,
          top: width >= breakpoints.desktopS ? 0 : 100,
        },
      });
      setOverlayTriggeredByButton(false);
    }
  }, [overlayTriggeredByButton]);

  useEffect(() => {
    if (!selectedEvents) return;
    const newZoom = selectedEvents.zoom * zoomMultiplicator;
    map.current.flyTo({
      center: selectedEvents.coordinates,
      zoom: newZoom,
      speed: 1,
      essential: true,
      padding: {
        right:
          selectedEvents.storyElement && width >= breakpoints.desktopS
            ? 440
            : 0,
        top: width >= breakpoints.desktopS ? 0 : 100,
      },
    });
  }, [selectedEvents]);

  useEffect(() => {
    if (!mapContainer.current) return;
    const multiplicatorScale = scaleLinear()
      .domain([320, 1600, 2200])
      .range([0.9, 1, 1.1]);
    const windowWidth = mapContainer.current.offsetWidth;
    const zoomMulti = multiplicatorScale(windowWidth);
    map.current.jumpTo({
      zoom: zoom * zoomMulti,
    });
    setZoomMultiplicator(zoomMulti);
  }, []);

  return (
    <MapContainer ref={mapContainer} className="map-container">
      <Stations
        map={map.current}
        date={date}
        setSelectedStation={setSelectedStation}
        selectedCategory={category}
        setTooltipPosition={setTooltipPosition}
        setTooltipVisible={setTooltipVisible}
        dwdData={dwdData}
        setDwdData={setDwdData}
      />
      <Tooltip
        dwdData={dwdData}
        date={date}
        category={category}
        selectedStation={selectedStation as keyof Object}
        tooltipPosition={tooltipPosition}
        tooltipVisible={tooltipVisible}
      ></Tooltip>
      <VideoEvents
        map={map.current}
        selectedEvents={selectedEvents}
        setVideoOpen={setVideoOpen}
        setVideoData={setVideoData}
        overlayOpen={overlayOpen}
        width={width}
      />
    </MapContainer>
  );
};

export default MapboxMapStandard;
