import React from "react";
import styled from "styled-components";
import { breakpoints, colors, fonts, fontSizes } from "../models";

const HighlightedBody = styled.b`
  font-weight: 600;
  @media (min-width: ${breakpoints.desktopS}px) {
    font-weight: 600;
  }
`;

const LinkNumber = styled.a`
  font-size: ${fontSizes.micro};
  font-weight: ${fonts.weightBold};
  text-decoration: none;
  color: ${colors.black};
`;

export const MainText = [
  {
    selectedId: "Hitzesommer2003",
    id: "Hitzesommer2003Text",
    headline: "Alarmierender Rekord",
    date: "",
    videos: false,
    playing: true,
    text: (
      <>
        <p>
          Die drei heißesten Sommer der Mess&shy;geschichte waren alle nach
          2000.
          <LinkNumber
            href="https://www.dwd.de/DE/presse/pressemitteilungen/DE/2020/20200702_dach_news.html"
            target={"_blank"}
          >
            <sup>[1]</sup>
          </LinkNumber>{" "}
          Dazu gehört auch der Hitze&shy;sommer 2003. In diesem Jahr starben
          alleine in Deutsch&shy;land 9.355 Menschen an den Folgen der Hitze.
          Besonders bei geschwächten Personen kann es bei hohen Temperaturen zu
          Lungen&shy;versagen und Herz&shy;infarkten kommen.
        </p>
        <p>
          Auf der Karte siehst du vergleichsweise milden Temperaturen zum
          Sommer&shy;anfang am 01.06.2003. Besonders heiß wurde es Anfang
          August.{" "}
          <HighlightedBody>
            Der bisherige deutsche Hitze&shy;rekord wurde am 08.08.2013 mit
            40,3°C gebrochen.
          </HighlightedBody>
          <LinkNumber
            href="https://de.wikipedia.org/wiki/Hitzewelle_in_Europa_2003"
            target={"_blank"}
          >
            <sup>[2]</sup>
          </LinkNumber>{" "}
          Nutze den Slider um dir die Tempera&shy;turen an diesem Tag anzusehen.
        </p>
      </>
    ),
  },
  {
    selectedId: "Hochwasser2013",
    id: "Hochwasser2013Text",
    headline: "Deutschland unter Wasser",
    date: "",
    videos: true,
    playing: false,
    text: (
      <>
        <p>
          Neben Hitze&shy;wellen werden auch Starkregen&shy;ereignisse durch den
          Klima&shy;wandel wahr&shy;scheinlicher. Ende Mai und Anfang Juni 2013
          kam es durch tagelange Regen&shy;fälle zu Über&shy;schwemmungen in
          Mittel&shy;europa.{" "}
          <HighlightedBody>
            Weite Teile Deutschlands waren betroffen, insbesondere Gebiete
            entlang Donau, Saale, Elbe und weiterer Flüsse.
          </HighlightedBody>
          <LinkNumber
            href="https://www.eskp.de/naturgefahren/das-juni-hochwasser-2013-in-deutschland-935306/"
            target={"_blank"}
          >
            <sup>[3]</sup>
          </LinkNumber>
        </p>
        <p>
          Umweltschützer*innen nennen Deichbau, Fluss&shy;begradigungen,
          Boden&shy;versiegelungen und den Verlust natürlicher
          Überflutungs&shy;flächen als Ursachen stärkerer Überschwemmungen.
          <LinkNumber
            href="https://www.bpb.de/kurz-knapp/hintergrund-aktuell/163064/hochwasser-in-deutschland-2013/"
            target={"_blank"}
          >
            <sup>[4]</sup>
          </LinkNumber>{" "}
        </p>
      </>
    ),
  },
  {
    selectedId: "Hitzesommer2018",
    id: "Hitzesommer2018Text",
    headline: "Es wird immer heißer",
    date: "",
    videos: true,
    playing: false,
    text: (
      <>
        <p>
          Die Monate von April bis Juli 2018 waren so heiß wie noch nie seit
          Beginn der Wetter&shy;aufzeichnung. In Kombination mit geringen
          Regen&shy;fällen führten die hohen Tempera&shy;turen dazu, dass{" "}
          <HighlightedBody>
            bis Mitte August 89% der Flächen in Deutschland von Dürre betroffen
            waren.
          </HighlightedBody>
          <LinkNumber
            href="https://www.scinexx.de/news/geowissen/hitzesommer-2018-brach-rekorde/"
            target={"_blank"}
          >
            <sup>[5]</sup>
          </LinkNumber>{" "}
        </p>
        <p>
          Parallel zu den neuen Hitze&shy;rekorden beginnen an Freitagen die
          ersten Schul&shy;streiks in Deutschland. Inspiriert von der damals
          15-jährigen Greta Thunberg, dienen sie als Protest&shy;form gegen eine
          mangelhafte Klimapolitik.
          <LinkNumber
            href="https://www.klimareporter.de/erdsystem/deutschland-kein-jahr-war-heisser-als-2018"
            target={"_blank"}
          >
            <sup>[6]</sup>
          </LinkNumber>{" "}
        </p>
      </>
    ),
  },
  {
    selectedId: "Hitzesommer2019",
    id: "Hitzesommer2019Text",
    headline: "Neue Höchstwerte",
    date: "",
    videos: true,
    playing: false,
    text: (
      <>
        <p>
          Am 25. Juli 2019 wird die in Deutschland bislang höchste gemessene
          Temperatur von 40,3°C gleich an mehreren Stationen überschritten.{" "}
          <HighlightedBody>
            In Lingen in Nieder&shy;sachen werden an diesem Tag 42,6°C gemessen.
          </HighlightedBody>{" "}
          Die zweite Juli&shy;hälfte brachte eine enorme Hitzewelle, die
          besonders den Westen Deutschlands traf.
          <LinkNumber
            href="https://www.wetter.de/cms/hitzewelle-juli-2019-als-die-hitze-deutschland-fest-im-griff-hatte-4577741.html"
            target={"_blank"}
          >
            <sup>[7]</sup>
          </LinkNumber>{" "}
          Wie schon im Jahr zuvor kam es zu starken Dürren, auch weil die
          Nieder&shy;schlags&shy;menge deutlich hinter dem Sollwert zurück
          blieb.
          <LinkNumber
            href="https://www.nationalgeographic.de/umwelt/2019/09/rekordhitze-und-duerre-der-sommer-2019-war-extrem"
            target={"_blank"}
          >
            <sup>[8]</sup>
          </LinkNumber>{" "}
        </p>
      </>
    ),
  },
  {
    selectedId: "Sturzflut2021",
    id: "Sturzflut2021Text",
    headline: "Ahrtal",
    date: "",
    videos: true,
    playing: false,
    text: (
      <>
        <p>
          Eine Katastrophe, die den meisten Menschen in Deutschland noch im
          Gedächtnis sein dürfte waren die Über&shy;schwemmungen und Sturzfluten
          im Ahrtal. Besonders heftige Regen&shy;fälle am 14.7.2021 und am
          15.7.2021 sorgten dafür, dass die Ahr einen Rekordpegel von 5 Metern
          erreichte.{" "}
          <HighlightedBody>
            Eine Kiesgrube in Erftstadt Blessem stürzte infolge der
            Über&shy;flutung des Flusses Erft ein, wodurch zahlreiche Häuser
            mitgerissen wurden.
          </HighlightedBody>
          <LinkNumber
            href="https://www.mdr.de/brisant/hochwasser-ahrtal-100.html"
            target={"_blank"}
          >
            <sup>[9]</sup>
          </LinkNumber>{" "}
        </p>
        <p>
          Die verursachten Gesamt&shy;schäden belaufen sich auf über 40
          Millionen € und 183 Menschen starben durch die Katastrophe. Zahlreiche
          Dörfer im Ahrtal wurden komplett zerstört.
        </p>
      </>
    ),
  },
  {
    selectedId: "fazit",
    id: "FazitText",
    headline: "Fazit",
    date: "",
    videos: true,
    playing: false,
    text: (
      <>
        <p>
          Schreitet der Klimawandel wie bisher voran, wird er intensivere
          Hitze&shy;sommer und stärkere Über&shy;schwemmungen verursachen. Das
          bedeutet zum einen enorme Kosten durch Zerstörung von
          Infra&shy;struktur und aus&shy;bleibenden Ernten. Zum anderen fordern
          Extrem&shy;wetter&shy;ereignisse insbesondere bei vulnerablen Gruppen
          immer wieder viele Todes&shy;opfer. Neben einem besseren Umgang im
          Katastrophen&shy;schutz, müssen also starke Maßnahmen getroffen
          werden, um den Anstieg der Temperatur zu minimieren.
        </p>
        <p>
          Nutze die Zeitleiste unter der Karte um dir weitere Ereignisse und
          zugehörige Video&shy;berichte anzusehen.
        </p>
      </>
    ),
  },
];
