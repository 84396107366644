import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ArrowRight from "../../images/svg/arrow-right.svg";
import Map from "../../images/svg/map.svg";
import Article from "../../images/svg/article.svg";

import { breakpoints, button, buttonStyle, colors, sizes } from "../../models";

interface OverlayButtonProps {
  overlayOpen: boolean;
  setOverlayOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvents: any;
  width: string;
  scrollySelectedId: string;
  handleSelectedData: (selectedId: string) => void;
  setOverlayButtonControl: React.Dispatch<React.SetStateAction<boolean>>;
  setOverlayTriggeredByButton: React.Dispatch<React.SetStateAction<boolean>>;
}

const Button = styled.button<any>`
  ${buttonStyle};
  color: ${colors.black};
  position: absolute;
  padding: 0;
  border-radius: 100px;
  height: 40px;
  z-index: 4;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${sizes.fine}px;
  display: ${(props) => (props.isHero ? "none" : "flex")};
  box-shadow: 0px 2px 20px ${colors.blackShadow};

  @media (min-width: ${breakpoints.desktopS}px) {
    box-shadow: none;
    display: flex;
    margin-top: ${sizes.petite}px;
    transform: ${(props) =>
      props.overlayOpen ? "translateX(50%)" : `translateX(-${sizes.twice}px)`};
    right: ${(props) => (props.overlayOpen ? "440px" : `0`)};
    left: auto;
    bottom: auto;
    height: 50px;
  }
`;

const ArrowContainer = styled.div`
  display: none;
  @media (min-width: ${breakpoints.desktopS}px) {
    display: block;
  }
`;

const ButtonText = styled.span`
  ${button};
  display: none;
  @media (min-width: ${breakpoints.desktopS}px) {
    padding-right: ${sizes.petite}px;
    display: block;
  }
`;
const ButtonTextMobile = styled.span`
  ${button};
  padding: 0 ${sizes.base}px 0 ${sizes.fine}px;
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.desktopS}px) {
    display: none;
  }
`;

const OverlayButton = ({
  overlayOpen,
  setOverlayOpen,
  selectedEvents,
  scrollySelectedId,
  handleSelectedData,
  setOverlayButtonControl,
  setOverlayTriggeredByButton,
}: OverlayButtonProps) => {
  const [isHero, setIsHero] = useState<boolean>(true);

  useEffect(() => {
    if (!selectedEvents) return;
    selectedEvents.isTitle ? setIsHero(true) : setIsHero(false);
  }, [selectedEvents]);

  const handleButtonClick = () => {
    setOverlayTriggeredByButton(true);
    setOverlayButtonControl(true);
    if (overlayOpen) {
      setOverlayOpen(false);
    } else {
      setOverlayOpen(true);
      if (scrollySelectedId) handleSelectedData(scrollySelectedId);
    }
  };

  return (
    <>
      <Button
        onClick={handleButtonClick}
        overlayOpen={overlayOpen}
        isHero={isHero}
      >
        <ArrowContainer>
          <ArrowRight
            style={{
              width: "50px",
              height: "50px",
              transform: `rotate(${overlayOpen ? 0 : 180}deg)`,
              transition: "transform 0.2s",
            }}
          />
        </ArrowContainer>
        {overlayOpen ? "" : <ButtonText>Zurück zur Story</ButtonText>}
        <ButtonTextMobile>
          {overlayOpen ? (
            <>
              <Map
                width={"22px"}
                height={"22px"}
                style={{ padding: "0 4px" }}
              />
              <span>Karte erkunden</span>
            </>
          ) : (
            <>
              <Article
                width={"22px"}
                height={"22px"}
                style={{ padding: "0 4px" }}
              />
              Zurück zur Story
            </>
          )}
        </ButtonTextMobile>
      </Button>
    </>
  );
};

export default OverlayButton;
