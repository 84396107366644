import React from "react";
import styled, { keyframes } from "styled-components";
import ArrowRight from "../../images/svg/arrow-right.svg";
import {
  body,
  breakpoints,
  colors,
  h3,
  Link,
  ScreenHeight75,
  sizes,
} from "../../models";

const EinleitungContainer = styled.div`
  padding-top: 30vh;
`;

const TextContainer = styled.div`
  background: ${colors.orangeOverlay70};
  padding: ${sizes.fine}px ${sizes.base}px 0 ${sizes.base}px;
  border-top: 1px solid ${colors.gridLight};
  border-bottom: 1px solid ${colors.gridLight};
  backdrop-filter: blur(6px);

  @media (min-width: ${breakpoints.textBox}px) {
    border: 1px solid ${colors.gridLight};
    border-radius: ${sizes.fine}px;
  }

  @media (min-width: ${breakpoints.desktopS}px) {
    background: none;
    border: none;
    backdrop-filter: none;
    padding: 0 ${sizes.twice}px ${sizes.petite}px ${sizes.petite}px;
  }
`;

const BodyText = styled.div`
  ${body};
  p {
    margin: 0;
    padding: ${sizes.fine}px 0 ${sizes.petite}px 0;
  }
`;

const Einleitung = () => {
  return (
    <EinleitungContainer>
      <TextContainer id={"Einleitung"}>
        <BodyText>
          <p>
            Der Klimawandel hat direkten Einfluss auf Intensität und Häufigkeit
            von Stark&shy;regen und Hitze&shy;wellen. Das zeigen die{" "}
            <Link
              href="https://www.raonline.ch/pages/edu/pdfCLI/IPCC_AR6WGI2101.pdf"
              target="_blank"
            >
              Berichte vom IPCC aus dem Jahr 2021
            </Link>
            . Diese Daten&shy;story zeigt Extrem&shy;wetter&shy;ereignisse
            zwischen 2000 und 2021, die durch hohe Tempera&shy;turen und
            Nieder&shy;schlags&shy;mengen verursacht wurden. Die Daten dafür
            stammen vom{" "}
            <Link href="https://opendata.dwd.de/" target="_blank">
              Deutschen Wetter&shy;dienst
            </Link>{" "}
            und einer{" "}
            <Link
              href="https://www.prognos.com/de/projekt/bezifferung-von-klimafolgekosten-deutschland"
              target="_blank"
            >
              Studie über Klimaschäden von prognos
            </Link>
            , die vom Bundes&shy;ministerium für Wirtschaft und Klimaschutz in
            Auftrag gegeben wurde.
          </p>
          <p>
            Du kannst die Geschichte jederzeit verlassen und mit dem Stepper
            unter der Karte ein Ereignis anwählen, das dich interessiert. Viel
            Spaß!
          </p>
        </BodyText>
        <span id="waypoint" data-selected="title"></span>
      </TextContainer>
      <ScreenHeight75 />
    </EinleitungContainer>
  );
};

export default Einleitung;
