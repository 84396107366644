import React from "react";
import styled from "styled-components";
import {
  body,
  breakpoints,
  colors,
  fontSizes,
  label,
  Link,
  ScreenHeight25,
  sizes,
} from "../../models";
import fhp from "../../images/fhp-logo.png";
import honigmitoe from "../../images/honigmitoe-logo.png";
import Insta from "../../images/svg/insta.svg";

const FooterContainer = styled.div`
  background: ${colors.orangeOverlay70};
  padding: ${sizes.petite}px ${sizes.base}px ${sizes.wide}px ${sizes.base}px;
  border-radius: ${sizes.fine}px ${sizes.fine}px 0 0;
  backdrop-filter: blur(6px);
  box-shadow: 0px 6px 20px 6px ${colors.blackShadow};

  @media (min-width: ${breakpoints.desktopS}px) {
    margin: auto;
    background: none;
    padding: 0 ${sizes.twice}px ${sizes.wide}px ${sizes.petite}px;
    box-shadow: none;
    backdrop-filter: none;
  }
`;

const LogoContainer = styled.div`
  padding-bottom: ${sizes.petite}px;
  border-bottom: 1.5px solid ${colors.black};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FHPLogo = styled.img`
  height: 35px;

  @media (min-width: ${breakpoints.textBox}px) {
    height: 45px;
  }
`;

const HonigmitoeLogo = styled.img`
  height: 50px;

  @media (min-width: ${breakpoints.textBox}px) {
    height: 60px;
  }
`;

const FooterText = styled.div`
  ${body};
  padding: ${sizes.petite}px 0;
  border-bottom: 1.5px solid ${colors.black};
`;

const HighlightedBody = styled.b`
  font-weight: 600;
  @media (min-width: ${breakpoints.desktopS}px) {
    font-weight: 600;
  }
`;

const Footer = () => {
  return (
    <>
      <ScreenHeight25 />
      <FooterContainer>
        <LogoContainer>
          <FHPLogo src={fhp} />
          <HonigmitoeLogo src={honigmitoe} />
        </LogoContainer>
        <FooterText>
          <HighlightedBody>
            Konzept, Gestaltung und Development:
          </HighlightedBody>{" "}
          Tim Hönig
          <br />
          <HighlightedBody>Kontakt:</HighlightedBody>{" "}
          <Link href="mailto:tim@honigmitoe.de">tim@honigmitoe.de</Link>,{" "}
          <Link
            href="https://www.instagram.com/honig.mit.oe/"
            target="_blank"
            style={{
              display: "inline-flex",
              alignItems: "center",
              flexBasis: "auto",
            }}
          >
            <Insta
              width={"16px"}
              height={"16px"}
              style={{ paddingRight: "4px" }}
            />{" "}
            <span>honig.mit.oe</span>
          </Link>
          <br />
          <br />
          Das Projekt entstand im Rahmen meiner Bachelorarbeit unter der
          Betreuung von Prof. Dr. Sebastian Meier und Prof. Dr. Marian Dörk. Die
          verwendeten Daten stammen von der{" "}
          <Link href="https://opendata.dwd.de/" target="_blank">
            Open Data API des Deutschen Wetterdienstes
          </Link>{" "}
          und einer{" "}
          <Link
            href="https://www.prognos.com/de/projekt/bezifferung-von-klimafolgekosten-deutschland"
            target="_blank"
          >
            Studie von prognos
          </Link>
          .
        </FooterText>
        <FooterText>
          <HighlightedBody>Quellen:</HighlightedBody>
          <br />
          [1]{" "}
          <Link
            href="https://www.dwd.de/DE/presse/pressemitteilungen/DE/2020/20200702_dach_news.html"
            target="_blank"
          >
            Deutscher Wetterdienst (02.07.2020): Aus extrem wurde normal: Sommer
            in Deutschland, der Schweiz und Österreich immer heißer,
          </Link>{" "}
          [abgerufen am 01.02.2023].
          <br />
          [2]{" "}
          <Link
            href="https://de.wikipedia.org/wiki/Hitzewelle_in_Europa_2003"
            target="_blank"
          >
            Wikipedia (06.08.2022): Hitzewelle in Europa 2003,
          </Link>{" "}
          [abgerufen am 01.02.2023].
          <br />
          [3]{" "}
          <Link
            href="https://www.eskp.de/naturgefahren/das-juni-hochwasser-2013-in-deutschland-935306/"
            target="_blank"
          >
            ESKP (o.D.): Das Juni-Hochwasser 2013 in Deutschland,
          </Link>{" "}
          [abgerufen am 01.02.2023].
          <br />
          [4]{" "}
          <Link
            href="https://www.bpb.de/kurz-knapp/hintergrund-aktuell/163064/hochwasser-in-deutschland-2013/"
            target="_blank"
          >
            Bundeszentrale für politische Bildung (12.06.2013): Hochwasser in
            Deutschland 2013,
          </Link>{" "}
          [abgerufen am 01.02.2023].
          <br />
          [5]{" "}
          <Link
            href="https://www.scinexx.de/news/geowissen/hitzesommer-2018-brach-rekorde/"
            target="_blank"
          >
            Karlsruher Institut für Technologie (30.08.2018): Hitzesommer 2018
            brach Rekorde,
          </Link>{" "}
          [abgerufen am 01.02.2023].
          <br />
          [6]{" "}
          <Link
            href="https://www.klimareporter.de/erdsystem/deutschland-kein-jahr-war-heisser-als-2018"
            target="_blank"
          >
            Kirchner, Sandra (22.12.2018): Deutschland: Kein Jahr war heißer als
            2018,
          </Link>{" "}
          [abgerufen am 01.02.2023].
          <br />
          [7]{" "}
          <Link
            href="https://www.wetter.de/cms/hitzewelle-juli-2019-als-die-hitze-deutschland-fest-im-griff-hatte-4577741.html"
            target="_blank"
          >
            wetter.de (29.07.2020): Hitzewelle Juli 2019: Als die Hitze
            Deutschland fest im Griff hatte,
          </Link>{" "}
          [abgerufen am 01.02.2023].
          <br />
          [8]{" "}
          <Link
            href="https://www.nationalgeographic.de/umwelt/2019/09/rekordhitze-und-duerre-der-sommer-2019-war-extrem"
            target="_blank"
          >
            Voss, Jens (10.09.2019): Rekordhitze und Dürre: Der Sommer 2019 war
            extrem,
          </Link>{" "}
          [abgerufen am 01.02.2023].
          <br />
          [9]{" "}
          <Link
            href="https://www.mdr.de/brisant/hochwasser-ahrtal-100.html"
            target="_blank"
          >
            mdr.de (13.07.2022): EIN JAHR NACH DEM HOCHWASSER - DIE
            JAHRUNDERTFLUT IN ZAHLEN,
          </Link>{" "}
          [abgerufen am 01.02.2023].
        </FooterText>
      </FooterContainer>
    </>
  );
};

export default Footer;
