import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import {
  sizes,
  breakpoints,
  fonts,
  body,
  tooltipHeadline,
  label,
  colors,
} from "../../models";
import stationKlima from "../../data/station-klima.json";

interface TooltipProps {
  dwdData: Object | undefined;
  date: Date;
  category: string | undefined;
  selectedStation: keyof Object;
  selectedCategory?: string;
  tooltipPosition: [number, number];
  tooltipVisible: boolean;
}

const TooltipTitle = styled.div`
  ${tooltipHeadline};
`;

const TooltipText = styled.div`
  ${label}
`;

const TooltipTextCategory = styled.span`
  font-weight: ${fonts.weightBold};
`;

const TooltipContainer = styled.div<any>`
  position: absolute;
  background: ${colors.white80};
  padding: ${sizes.tiny}px ${sizes.base}px ${sizes.fine}px ${sizes.base}px;
  border-radius: 5px;
  border: 1px solid ${colors.gridLight};
  width: max-content;
  text-align: left;
  max-width: 150px;
  transform: translate(-50%, calc(-100% - ${sizes.base}px));
  z-index: 1;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.18));
  backdrop-filter: blur(5px);
  pointer-events: none;

  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 100%;
  //   left: 50%;
  //   margin-left: -2.5px;
  //   border: 20px solid ${colors.white80} transparent transparent transparent;
  // }

  @media (min-width: ${breakpoints.tabletL}px) {
    max-width: 200px;
  }

  @media (min-width: ${breakpoints.desktopL}px) {
    max-width: 270px;
  }
`;

const Tooltip = ({
  dwdData,
  date,
  category,
  selectedStation,
  tooltipPosition,
  tooltipVisible,
}: TooltipProps) => {
  const stationObject: any = stationKlima;
  const stationData: any = stationObject[selectedStation];
  const stationName = stationData.name;
  const stationBundesland = stationData.bundesland;
  const stationHeight = stationData.hoehe;
  const isTemperature = category === "temperature";
  const dateOffset = date?.getTimezoneOffset();
  const dateToTransform = new Date(date.getTime() - dateOffset * 60 * 1000);
  const yyyymmdd = dateToTransform
    .toISOString()
    .split("T")[0]
    .replace(/-/g, "");
  const stationValues: any = dwdData && dwdData[selectedStation];
  const value = dwdData && stationValues[yyyymmdd];

  return (
    <TooltipContainer
      style={{
        left: `${tooltipPosition[0]}px`,
        top: `${tooltipPosition[1]}px`,
        opacity: `${tooltipVisible ? 1 : 0}`,
      }}
    >
      <TooltipTitle>{stationName}</TooltipTitle>
      <TooltipText>
        <TooltipTextCategory>
          {isTemperature ? "Maximaltemperatur: " : "Niederschlagshöhe: "}
        </TooltipTextCategory>
        {isTemperature ? `${value}°C` : `${value} mm`}
      </TooltipText>
      <TooltipText>
        <TooltipTextCategory>Bundesland: </TooltipTextCategory>
        {stationBundesland}
      </TooltipText>
      <TooltipText>
        <TooltipTextCategory>Stationshöhe: </TooltipTextCategory>
        {stationHeight} mNN
      </TooltipText>
    </TooltipContainer>
  );
};

export default Tooltip;
