import React, { useState, useEffect } from "react";
import { select, axisBottom, scaleLinear, ScaleLinear, selectAll } from "d3";
import phenomena from "../../data/phenomena.json";
import { colors } from "../../models";
import { Icon } from "./icon";
import styled from "styled-components";

interface StepsProps {
  scaleX: any;
  selectedData: any;
  handleSelectedData: any;
  setCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  setOverlayButtonControl: React.Dispatch<React.SetStateAction<boolean>>;
  setTooltipContent: React.Dispatch<React.SetStateAction<string>>;
  setTooltipPos: React.Dispatch<React.SetStateAction<any>>;
  setCueVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const IconGroup = styled.g`
  &:hover {
    cursor: pointer;
  }
`;

export const Steps = ({
  scaleX,
  selectedData,
  handleSelectedData,
  setCategory,
  setPlaying,
  setOverlayButtonControl,
  setTooltipContent,
  setTooltipPos,
  setCueVisible,
}: StepsProps) => {
  const [selectedStep, setSelectedStep] = useState<string>("");
  const [visitedSteps, setVisitedSteps] = useState<any>([]);
  const [hoveredId, setHoveredId] = useState<string | undefined>(undefined);

  useEffect(() => {
    const currentStep = selectedData
      ? `${selectedData.Klimasignal}${selectedData.Zeitraum}`
      : "";
    setSelectedStep(currentStep);

    if (selectedData && !visitedSteps.includes(selectedStep))
      setVisitedSteps((oldArray: any) => [...oldArray, selectedStep]);
  }, [selectedData]);

  const createSteps = () => {
    const handleButtonClick = (event: any) => {
      const target = event.target.tagName;
      const selectedGroup =
        target === "circle"
          ? event.target.parentElement
          : event.target.parentElement.parentElement.parentElement;
      const selectedId = selectedGroup.id;
      const selectedCategory = selectedGroup.dataset.category;

      setCueVisible(false);
      setPlaying(false);
      setCategory(selectedCategory);
      handleSelectedData(selectedId);
      setOverlayButtonControl(false);
    };

    const handleMouseMove = (event: any) => {
      const target = event.target.tagName;
      const selectedGroup =
        target === "circle"
          ? event.target.parentElement
          : event.target.parentElement.parentElement.parentElement;
      const selectedId = selectedGroup.id;

      const circleX = selectAll(`#${selectedId}-circle`).attr("cx");
      const circleY = selectAll(`#${selectedId}-circle`).attr("cy");
      const tooltipContent =
        selectedId.slice(0, 2) === "Hi"
          ? selectedId.replace("Hitzesommer", "Hitzesommer ")
          : selectedId.slice(0, 2) === "Ho"
          ? selectedId.replace("Hochwasser", "Hochwasser ")
          : selectedId.replace("Sturzflut", "Sturzflut ");

      setHoveredId(selectedId);
      setTooltipContent(tooltipContent);
      setTooltipPos({
        x: Number(circleX) + 12,
        y: Number(circleY) - 50,
      });
    };

    const handleMouseOut = (event: any) => {
      setHoveredId(undefined);
      setTooltipContent("");
    };

    return (
      <>
        {Object.values(phenomena).map((value: any) => {
          const type = value.Klimasignal;
          const color = type === "Hitzesommer" ? colors.heat : colors.rain;
          const category = type === "Hitzesommer" ? "temperature" : "rain";
          const strokeWidth = 1;
          const radius = 13 - strokeWidth;
          const radiusBig = 16 - strokeWidth;
          const year = value.Zeitraum;
          const axisYPosition = 60;
          const id = `${type}${year}`;
          const transitionDuration = "0.5s";
          const opacityVisited = 0.4;

          return (
            <g key={`${type}${year}-step`}>
              <IconGroup
                id={id}
                data-category={category}
                className="icon-button"
                onClick={handleButtonClick}
                onMouseMove={handleMouseMove}
                onMouseOut={handleMouseOut}
              >
                <circle
                  id={`${id}-circle`}
                  cx={scaleX(year)}
                  cy={
                    selectedStep === id
                      ? radiusBig + strokeWidth
                      : radius + strokeWidth + 26
                  }
                  r={selectedStep === id ? radiusBig : radius}
                  fill={
                    selectedStep === id || hoveredId === id
                      ? color
                      : colors.orangeBackground
                  }
                  fillOpacity={
                    selectedStep != id &&
                    hoveredId === id &&
                    visitedSteps.includes(id)
                      ? opacityVisited
                      : 1
                  }
                  stroke={color}
                  strokeWidth={strokeWidth}
                  strokeOpacity={visitedSteps.includes(id) ? opacityVisited : 1}
                  style={{ transition: `cy ${transitionDuration} ease` }}
                />
                <g
                  transform={`translate(${scaleX(year) - 18 / 2},${
                    selectedStep === id
                      ? radiusBig + strokeWidth - 19 / 2
                      : radius + strokeWidth - 19 / 2 + 26
                  })`}
                  style={{ transition: `transform ${transitionDuration} ease` }}
                >
                  <Icon
                    key={`${type}${year}-step-icon`}
                    id={id}
                    hoveredId={hoveredId}
                    type={type}
                    selected={selectedStep === id ? true : false}
                    visited={visitedSteps.includes(id) ? true : false}
                    opacityVisited={opacityVisited}
                  />
                </g>
              </IconGroup>
              <path
                id={`${id}-line`}
                d={`M${scaleX(year)}, ${
                  selectedStep === id
                    ? radius * 2 + strokeWidth
                    : radius * 2 + strokeWidth + 26
                } L${scaleX(year)}, ${axisYPosition}`}
                strokeWidth={selectedStep === id ? 2 : strokeWidth}
                stroke={color}
                opacity={
                  visitedSteps.includes(id) && selectedStep != id
                    ? opacityVisited
                    : 1
                }
                style={{
                  transition: `d, stroke-width ${transitionDuration} ease`,
                }}
              />
            </g>
          );
        })}
      </>
    );
  };

  return <g>{createSteps()}</g>;
};
